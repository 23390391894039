/* eslint-disable sort-imports */

// Set global.applicationHistory so that on-location-changed will listen on it
import history from "shared/history.js"
import {callbacksHandler} from "on-location-changed/src/callbacks-handler"

callbacksHandler.connectReactRouterHistory(history)


// Use our own logging tool to log errors in tests
import "shared/error-logger"


// HayaSelect
import BodyPortal from "components/body-portal"
import {default as HayaSelectConfig} from "haya-select/src/config"

HayaSelectConfig.setBodyPortal(BodyPortal)


// API maker
import {default as ApiMakerConfig} from "@kaspernj/api-maker/src/config"
import CommandsPool from "@kaspernj/api-maker/src/commands-pool"
import currenciesCollection from "shared/currencies-collection"

ApiMakerConfig.setCurrenciesCollection(currenciesCollection)
ApiMakerConfig.setHistory(history)

CommandsPool.current().globalRequestData.locale = I18n.locale


// React layout and application
import "shared/google-tag-manager"
import "shared/react-native-vector-icons"
import "shared/source-maps"
import "components/application"
import "controllers"
import "stylesheets/application"
